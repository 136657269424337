<template>
    <BT-Blade-Items
        v-if="currentLocationID != null"
        @select="selectProduct"
        bladeName="stock-items"
        canBreakdown
        :canClose="false"
        :canRefresh="false"
        canSearchLocal
        :canSelect="false"
        :canUnselect="false"
        :dense="false"
        flexColumn
        hideBackButton
        :itemProperties="['ID','ProductName','Abbreviation']"
        :loadingMsg="loadingMsg"
        navigation="products"
        :onPullSuccessAsync="pullStock"
        :searchProps="['productName', 'abbreviation']"
        showList
        :showTable="false"
        :small="false"
        startRefresh
        :actualUsedHeight="160">
        <template v-slot:toolbar>
            <v-toolbar-title>
                <BT-Entity
                    navigation="locations"
                    ignorePermissions
                    :itemValue="currentLocationID"
                    itemText="locationName">
                    <template v-slot:prepend><span>Stock Levels: </span></template>
                </BT-Entity>
            </v-toolbar-title>
        </template>
        <template v-slot:listItem="{ item }">
            <v-list-item-content>
                <v-list-item-title>{{ item.productName }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.abbreviation }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
        <template v-slot:bottom="{ measurements }">
            <v-navigation-drawer
                v-if="currentProduct != null"
                v-model="drawer"
                app
                bottom
                clipped
                hide-overlay
                right
                style="height: 100%;"
                :width="450">
                <v-toolbar class="primary" dark dense>
                    <v-btn icon title="Close" @click.stop="drawer = false">
                        <v-icon v-if="!$vuetify.breakpoint.mobile">mdi-arrow-right</v-icon>
                        <v-icon v-else>mdi-arrow-down</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ showAdjustments ? 'Adjustments' : currentProduct.abbreviation }}</v-toolbar-title>
                    <v-spacer />
                    <!-- <span v-if="!showAdjustments">{{ currentStockItems.length }} x Batch{{ currentStockItems.length == 1 ? '' : 'es' }}</span> -->
                    <v-btn v-if="isLengthyArray(adjustments)" class="primary" @click="toggleAdjustments">
                        <span v-if="!showAdjustments">Review {{ adjustments.length }} Adjustment{{ adjustments.length == 1 ? '' : 's' }}</span>
                        <span v-else>Show Stock</span>
                    </v-btn>
                    <!-- <v-btn v-if="showAdjustments && isLengthyArray(adjustments)" class="primary" large @click="saveAdjustments">Save All</v-btn> -->
                </v-toolbar>

                <v-list v-if="showAdjustments" class="overflow-y-auto">
                    <template v-for="(item, index) in adjustments">
                        <v-list-item :key="index">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.product.productName }}
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="item.batch != null">
                                    DOM: {{ item.batch.dom | toShortDate }} | EXP: {{ item.batch.exp | toShortDate }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else>No Batch Info</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.newBatch">(New)</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.quantityToRelease > 0 || item.quantityToHold > 0 || item.quantityToWaste > 0">
                                    <v-row class="ma-0 pa-0">
                                        <span>Release: {{ item.quantityToRelease | toDisplayNumber }}</span>
                                        <v-spacer />
                                        <span>Hold: {{ item.quantityToHold | toDisplayNumber }}</span>
                                        <v-spacer />
                                        <span>Waste: {{ item.quantityToWaste | toDisplayNumber }}</span>
                                        <v-spacer />
                                    </v-row>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>{{ item.quantityInStock - item.available }}</v-list-item-action>
                        </v-list-item>
                        <v-divider class="my-1" :key="index + 'a'" />
                    </template>
                </v-list>
                <v-list v-else class="overflow-y-auto">
                    <template v-for="(item, index) in currentStockItems">
                        <v-list-item :key="index" class="px-2" @click.stop="currentStockItem = item">
                            <v-list-item-content>
                                <v-list-item-title v-if="item.batch != null">{{ item.batch.batchcode }}</v-list-item-title>
                                <v-list-item-title v-else>No Batch Info</v-list-item-title>
                                <v-list-item-subtitle v-if="item.batch != null">
                                    DOM: {{ item.batch.dom | toShortDate }} {{ item.batch.exp != null ? '| EXP:' : '| No Expiry' }} {{ item.batch.exp | toShortDate }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.batch != null && item.batch.isPending">(Pending)</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.newBatch">*NEW*</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.onHold > 0 || item.wasted > 0">
                                    <v-row class="ma-0 pa-0">
                                        <span>On Hold: {{ item.onHold | toDisplayNumber }}</span>
                                        <v-spacer />
                                        <span>Wasted: {{ item.wasted | toDisplayNumber }}</span>
                                        <v-spacer />
                                    </v-row>
                                </v-list-item-subtitle>
                                <v-slide-x-transition hide-on-leave>
                                    <v-list-item-subtitle v-if="item === currentStockItem" class="py-3">
                                        <v-btn @click.stop="releaseQuantity(item)" class="mx-1 px-2 primary" :disabled="item.onHold <= 0">
                                            <span v-if="item.quantityToRelease > 0">Releasing {{ item.quantityToRelease | toDisplayNumber }}</span>
                                            <span v-else>Release</span>
                                        </v-btn>
                                        <v-btn @click.stop="holdQuantity(item)" class="mx-1 px-2 warning" :disabled="item.available <= 0">
                                            <span v-if="item.quantityToHold > 0">Holding {{ item.quantityToHold | toDisplayNumber }}</span>
                                            <span v-else>Hold</span>
                                        </v-btn>
                                        <v-btn @click.stop="wasteQuantity(item)" class="mx-1 px-2 error" :disabled="item.available <= 0">
                                            <span v-if="item.quantityToWaste > 0">Wasting {{ item.quantityToWaste | toDisplayNumber }}</span>
                                            <span v-else>Waste</span>
                                        </v-btn>
                                    </v-list-item-subtitle>
                                </v-slide-x-transition>
                            </v-list-item-content>
                            <v-list-item-action>
                                <BT-Increment-Quantity-Edit
                                    v-if="item.newBatch"
                                    v-model="item.quantityInStock"
                                    isEditing
                                    :measurements="measurements"
                                    :productID="item.productID"
                                    :small="false"
                                    spanClass="span-lg" />
                                <BT-Increment-Quantity-Edit
                                    v-else
                                    v-model="item.quantityInStock"
                                    :goal="item.available"
                                    inline
                                    :isEditing="item.batch == null || !item.batch.isPending"
                                    :measurements="measurements"
                                    :productID="item.productID"
                                    :small="false"
                                    spanClass="span-lg" />
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider class="my-1" :key="index + 'a'" />
                    </template>
                </v-list>

                <v-btn v-if="!showAdjustments && !showingMoreBatches" block text @click="showMoreBatches">
                    Show more batches
                </v-btn>
                <BT-Dialog
                    v-else-if="!showAdjustments"
                    @ok="createBatch"
                    block
                    :getOnOpenAsync="openNewBatch"
                    icon=""
                    label="New Batch"
                    text="The batch I want isn't listed"
                    width="350">
                    <template v-slot="{ item }">
                        <v-slide-x-transition group>
                            
                            <BT-Field-Date
                                v-if="!item.noBatchInfo"
                                @change="updateBatchcode(item)"
                                clearable
                                isEditing
                                key="1"
                                label="Date Of Manufacture"
                                v-model="item.dom" />

                            <BT-Field-Date
                                v-if="!item.noBatchInfo"
                                clearable
                                isEditing
                                key="2"
                                label="Date Of Expiry"
                                v-model="item.exp" />

                            <BT-Field-Number
                                isEditing
                                key="3"
                                label="Quantity Made"
                                v-model.number="item.quantity" />

                            <BT-Field-String
                                v-if="!item.noBatchInfo"
                                isEditing
                                key="4"
                                label="Batchcode"
                                v-model="item.batchcode" />

                            <BT-Field-Checkbox
                                isEditing
                                key="5"
                                label="No Batch Info"
                                v-model="item.noBatchInfo" />

                        </v-slide-x-transition>
                    </template>
                </BT-Dialog>

                <v-overlay :value="loadingMsg != null" absolute class="text-center">
                    <v-progress-circular indeterminate size="64" />
                    <p>{{ loadingMsg || 'Loading' }}</p>
                </v-overlay>

                <template v-slot:append>
                    <v-btn v-if="showAdjustments && isLengthyArray(adjustments)" block class="primary" large @click="saveAdjustments">Save All</v-btn>
                </template>
            </v-navigation-drawer>
        </template>
    </BT-Blade-Items>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Stock-Checker-Hub',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTIncrementQuantityEdit: () => import('~components/BT-Increment-Quantity-Edit.vue'),
    },
    data: function() {
        return {
            allBatches: [],
            allItems: [],
            allStockItems: [],
            currentBatchProduct: null,
            currentLocationID: null,
            currentProduct: null,
            currentStockItem: null,
            currentStockItems: [],
            drawer: false,
            loadingMsg: null,
            measurements: [],
            msg: null,
            showAdjustments: false,
            showingMoreBatches: false,
        }
    },
    async mounted() {
        if (this.currentLocationID == null) {
            this.currentLocationID = this.selectedLocationID();

            if (this.currentLocationID == null) {
                this.currentLocationID = await this.$selectItem({
                    navigation: 'locations', 
                    itemText: 'locationName', 
                    itemValue: 'id',
                    required: true });
            }
        }
    },
    computed: {
        adjustments() {
            return this.allItems.filter(z => z.quantityInStock !== z.available || z.newBatch || z.quantityToRelease > 0 || z.quantityToHold > 0 || z.quantityToWaste > 0);
        }
    },
    methods: {
        async createBatch(batch) {
            try {
                this.loadingMsg = 'Adding Batch';

                this.allItems.push({
                    id: null,
                    rowVersion: null,
                    locationID: this.currentLocationID,
                    productID: batch.productID,
                    product: this.currentProduct,
                    batchID: batch.id,
                    batch: batch,
                    onHold: 0,
                    available: 0,
                    quantityInStock: batch.quantity,
                    quantityToHold: 0,
                    quantityToRelease: 0,
                    quantityToWaste: 0,
                    wasted: 0,
                    newBatch: true
                });

                var pRes = this.allItems.filter(z => z.productID == this.currentProduct.id);
                pRes.sort(firstBy(z => z.batch != null).thenBy(z => z.batch.exp));
                this.currentStockItems = pRes;

            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        filterStock(stockItems) {
            var r = stockItems;
            r.sort(firstBy(z => z.batch != null).thenBy(z => z.batch != null && z.batch.exp));
        },
        async holdQuantity(stockItem) {
            if (stockItem != null) {
                try {
                    var original = stockItem.quantityToHold;
                    var qty = await this.$selectQuantity({
                        value: stockItem.quantityToHold,
                        label: 'Hold',
                        max: stockItem.available,
                        min: 0
                    })

                    if (!Number.isNaN(qty)) {
                        var dif = qty - original;
                        stockItem.quantityToHold = qty;
                        stockItem.quantityInStock -= dif;
                        stockItem.available -= dif;
                    }
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
            }
        },
        async openNewBatch() {
            this.currentBatchProduct = await this.$BlitzIt.store.get('products', this.currentProduct.id);
            var prod = this.currentBatchProduct;

            return {
                noBatchInfo: false,
                locationID: this.currentLocationID,
                dom: this.getToday(),
                exp: prod.expiryDays > 0 ? this.$BlitzIt.auth.formUTC(this.getToday(), null, prod.expiryDays) : null,
                originalBatchID: null,
                productID: prod.id,
                quantity: 0,
                quantityGoal: 0,
                quantityUsed: 0,
                batchcode: prod.batchcodeFormat != null ? this.$BlitzIt.auth.formTZ(this.getToday(), prod.batchcodeFormat) : null
            };
        },
        async pullStock(products, refresh) {
            this.allStockItems = await this.$BlitzIt.store.getAll('stock-items', { locationID: this.currentLocationID }, refresh);
            this.allBatches = await this.$BlitzIt.store.getAll('batches', null, refresh);

            var l = [];

            this.allStockItems.forEach(stk => {
                var existing = l.find(z => z.productID == stk.productID && z.batchID == stk.batchID);
                if (existing == null) {
                    existing = Object.assign({}, { ...stk, quantityInStock: 0, quantityToHold: 0, quantityToRelease: 0, quantityToWaste: 0 });
                    l.push(existing);
                }

                existing.quantityInStock = stk.available;
            });

            this.allBatches.forEach(batch => {
                var existing = l.find(z => z.productID == batch.productID && z.batchID == batch.id);
                if (existing == null) {
                    l.push({
                        id: null,
                        rowVersion: null,
                        locationID: this.currentLocationID,
                        productID: batch.productID,
                        product: batch.product,
                        batchID: batch.id,
                        batch: batch,
                        onHold: 0,
                        available: 0,
                        quantityInStock: 0,
                        quantityToHold: 0,
                        quantityToRelease: 0,
                        quantityToWaste: 0,
                        wasted: 0,
                        newBatch: false
                    });
                }
            })
            
            this.allItems = l;

            return products;
        },
        async releaseQuantity(stockItem) {
            if (stockItem != null) {
                try {
                    var qty = 0;
                    var original = 0;

                    var batch = await this.$BlitzIt.store.get('batches', stockItem.batchID);
                    if (batch != null && batch.onHold) {
                        //release batch
                        batch.onHold = false;
                        await this.$BlitzIt.store.patch('batches', batch);

                        stockItem.available = stockItem.onHold;
                        stockItem.quantityInStock = stockItem.onHold;
                        stockItem.onHold = 0;
                    }
                    else {
                        original = stockItem.quantityToRelease;

                        qty = await this.$selectQuantity({
                            value: stockItem.quantityToRelease,
                            label: 'Release',
                            max: stockItem.onHold,
                            min: 0
                        })

                        if (!Number.isNaN(qty)) {
                            var dif = qty - original;
                            stockItem.quantityToRelease = qty;
                            stockItem.quantityInStock += dif;
                            stockItem.available += dif;
                        }
                    }
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
            }
        },
        async saveAdjustments() {
            try {
                var toSave = this.allItems.filter(z => z.quantityInStock != z.available || z.quantityToRelease > 0 || z.quantityToWaste > 0 || z.quantityToHold > 0);
                var total = toSave.length;

                if (total > 0) {
                    for (let i = 0; i < total; i++) {
                        const item = toSave[i];
                        this.loadingMsg = `Saving ${i} of ${total} adjustments`;
                        
                        if (item.newBatch) {
                            if (item.batch.noBatchInfo === true) {
                                var newAdjustment = {
                                    adjustmentType: 'Add',
                                    quantity: item.batch.quantity,
                                    productID: item.productID,
                                    batchID: null,
                                    locationID: this.currentLocationID
                                }

                                item.available = item.batch.quantity;

                                await this.$BlitzIt.store.post('stock-adjustments', newAdjustment);
                            }
                            else {
                                var res = await this.$BlitzIt.store.post('batches', item.batch);
                                item.batchID = res.id;
                                item.available = res.quantity;
                            }

                            item.newBatch = false
                        }
                        else {
                            var inStockDif = item.quantityInStock - item.available;

                            if (inStockDif !== 0) {
                                var adjType = 'Add';

                                if (inStockDif < 0) {
                                    inStockDif = 0 - inStockDif;
                                    adjType = 'Remove';
                                }

                                var adjustment = {
                                    adjustmentType: adjType,
                                    quantity: inStockDif,
                                    productID: item.productID,
                                    batchID: item.batchID,
                                    locationID: this.currentLocationID
                                }

                                item.available = item.quantityInStock;

                                await this.$BlitzIt.store.post('stock-adjustments', adjustment);
                            }

                            if (item.quantityToHold > 0) {
                                await this.$BlitzIt.store.post('stock-adjustments', {
                                    adjustmentType: 'Hold',
                                    quantity: item.quantityToHold,
                                    productID: item.productID,
                                    batchID: item.batchID,
                                    locationID: this.currentLocationID
                                })

                                item.onHold += item.quantityToHold;
                                item.quantityToHold = 0;
                            }

                            if (item.quantityToRelease > 0) {
                                await this.$BlitzIt.store.post('stock-adjustments', {
                                    adjustmentType: 'Hold',
                                    quantity: 0 - item.quantityToRelease,
                                    productID: item.productID,
                                    batchID: item.batchID,
                                    locationID: this.currentLocationID
                                })

                                item.onHold -= item.quantityToRelease;
                                item.quantityToRelease = 0;
                            }

                            if (item.quantityToWaste > 0) {
                                await this.$BlitzIt.store.post('stock-adjustments', {
                                    adjustmentType: 'Waste',
                                    quantity: item.quantityToWaste,
                                    productID: item.productID,
                                    batchID: item.batchID,
                                    locationID: this.currentLocationID
                                })

                                item.wasted += item.quantityToWaste;
                                item.quantityToWaste = 0;
                            }
                        }
                    }
                }

                this.showAdjustments = false;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
                console.log('msg');
                console.log(this.extractErrorDescription(err));
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async selectProduct(product) {
            this.showAdjustments = false;
            this.showingMoreBatches = false;

            if (product == null) {

                this.currentProduct = null;
                this.currentStockItems = [];
                this.drawer = false;
            }
            else {
                this.currentProduct = product;
                var res = this.allItems.filter(z => z.productID == product.id && (z.quantityInStock != 0 || z.onHold !== 0 || z.quantityToRelease > 0 || z.quantityToWaste > 0 || z.quantityToHold > 0));
                res.sort(firstBy(z => z.batch != null).thenBy(z => z.batch.exp));
                this.currentStockItems = res;
                this.drawer = true;
            }
        },
        showMoreBatches() {
            if (this.currentProduct != null) {
                var res = this.allItems.filter(z => z.productID == this.currentProduct.id);
                res.sort(firstBy(z => z.batch != null).thenBy(z => z.batch.exp));
                this.currentStockItems = res;
                this.showingMoreBatches = true;
            }
        },
        toggleAdjustments() {
            if (this.drawer == true) {
                this.showAdjustments = !this.showAdjustments;
            }
            else {
                this.showAdjustments = true;
            }
            this.drawer = true;
        },
        async updateBatchcode(item) {
            if (item.productID == null || item.dom == null) {
                item.batchcode = null;
                item.exp = null;
            }
            else {
                var prod = this.currentBatchProduct;

                if (prod != null) {
                    item.batchcode = prod.batchcodeFormat != null ? this.$BlitzIt.auth.formTZ(item.dom, prod.batchcodeFormat) : null;
                    item.exp = prod.expiryDays > 0 ? this.$BlitzIt.auth.formUTC(item.dom, null, prod.expiryDays) : null;
                }
            }
        },
        async wasteQuantity(stockItem) {
            if (stockItem != null) {
                try {
                    var original = stockItem.quantityToWaste;
                    var qty = await this.$selectQuantity({
                        value: stockItem.quantityToWaste,
                        label: 'Waste',
                        max: stockItem.available,
                        min: 0
                    })

                    if (!Number.isNaN(qty)) {
                        var dif = qty - original;
                        stockItem.quantityToWaste = qty;
                        stockItem.quantityInStock -= dif;
                        stockItem.available -= dif;
                    }
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
            }
        }
    }
}
</script>